<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h5 class="card-title m-0">
            <i class="fa fa-user-circle"></i> Profile
          </h5>
        </div>
        <div class="card-body">
          <template v-if="xhr.profile.loading">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <br />
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <br />
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </template>
          <template v-else>
            <div class="text-center" v-if="xhr.profile.success">
              <h3>{{ xhr.profile.record.nama.toUpperCase() }}</h3>
              <p>({{ xhr.profile.record.email }})</p>
            </div>
            <table
              class="table table-borderless table-sm"
              v-if="xhr.profile.success"
            >
              <!--              <tr>
                <td>Name</td>
                <td>:</td>
                <td>{{ xhr.profile.record.nama }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>{{ xhr.profile.record.email }}</td>
              </tr>-->
              <!--              <tr>
                <td>Position</td>
                <td>:</td>
                <td>{{ xhr.profile.record.jabatan }}</td>
              </tr>-->
              <tr>
                <td>Department</td>
                <td>:</td>
                <td>
                  {{ xhr.profile.record.dept_name }} (<span
                    class="badge badge-info"
                    >{{ xhr.profile.record.jabatan }}</span
                  >)
                </td>
              </tr>
              <tr>
                <td>Shift</td>
                <td>:</td>
                <td>{{ xhr.profile.record.jam }}</td>
              </tr>
              <tr>
                <td>Shift Date</td>
                <td>:</td>
                <td>{{ xhr.profile.record.shiftdate }}</td>
              </tr>
            </table>
            <div class="alert alert-danger" v-else>
              {{ xhr.profile.record }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- /.col-md-6 -->
    <div class="col-lg-6">
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h5 class="card-title m-0">
            <i class="fa fa-lock"></i> Change Password
          </h5>
        </div>
        <div class="card-body">
          <form @submit.stop.prevent="changePasswordProcess">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="Enter old password"
                autocomplete="off"
                v-model="change_password.form.old"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="Enter new password"
                autocomplete="off"
                v-model="change_password.form.new"
              />
              <small
                >Minimal 8 karakter, terdapat setidaknya 1 huruf besar, 1 huruf
                kecil, 2 angka, dan 1 simbol</small
              >
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="Enter confirm password"
                autocomplete="off"
                v-model="change_password.form.confirm"
              />
              <small
                >Minimal 8 karakter, terdapat setidaknya 1 huruf besar, 1 huruf
                kecil, 2 angka, dan 1 simbol</small
              >
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "HomePage",
  mixins: [request, loader],
  data() {
    return {
      xhr: {
        profile: {
          record: {},
          success: false,
          loading: true,
        },
      },
      change_password: {
        form: {
          old: "",
          new: "",
          confirm: "",
        },
      },
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * get profile current agent
     * --------------------------------------------------------------
     */
    getProfile: function () {
      this.API.post(this.URL.agent.profile, { result: "row" })
        .then(({ data }) => {
          this.xhr.profile.record = data.data;
          this.xhr.profile.success = true;
        })
        .catch((err) => {
          this.xhr.profile.record = err;
          this.xhr.profile.success = false;
        })
        .finally(() => {
          /**
           * --------------------------------------------------------------
           * kasih tem-out biar krasa skeleton nya :)
           * --------------------------------------------------------------
           */
          setTimeout(() => {
            this.xhr.profile.loading = false;
          }, 1000);
        });
    },
    /**
     * --------------------------------------------------------------
     * proses change password req api
     * --------------------------------------------------------------
     */
    changePasswordProcess: function () {
      this.$swal({
        title: "Are you sure?",
        html: "You will change your password account.",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          let data = this.change_password.form;
          if (data.old !== data.new) {
            if (data.new === data.confirm) {
              this.loading();
              this.API.post(
                this.URL.agent.changePassword,
                this.change_password.form
              )
                .then(() => {
                  this.$swal(
                    "Success",
                    "Password has been updated.",
                    "success"
                  );
                  this.change_password.form = {
                    new: "",
                    old: "",
                    confirm: "",
                  };
                })
                .catch((err) => {
                  let message =
                    typeof err.response !== "undefined"
                      ? err.response.data.message
                      : err.message;
                  this.$swal("Error", message, "error");
                })
                .finally(() => {
                  this.loading(false);
                });
            } else {
              this.$swal(
                "Failed",
                "New password and confirm password is not match.",
                "error"
              );
            }
          } else {
            this.$swal(
              "Failed",
              "New password can't be same with old password.",
              "error"
            );
          }
        } else {
          this.$swal("Cancelled", "", "success");
        }
      });
    },
  },
  mounted() {
    this.getProfile();
    this.$store.dispatch("breadcrumb", { title: "Home", path: "Home" });
    this.$store.dispatch("activeLink", this.$route.path);
  },
};
</script>

<style scoped></style>
